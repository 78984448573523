export const baseURL = process.env.REACT_APP_API_URL;
export const baseWebURL = process.env.REACT_APP_URL;
export const basefileURL = process.env.REACT_APP_API_ASSETS_URL;




export const urls = {
  ehrchartpack: {
    getbyId: "ChartPack/GetById",
    chartpack: "ChartPack/GetAll",
    getformbychartpackid: "ChartPackForms/GetAllByChartPack",
    savechartPack: "PatientChartPackForms/Save",
    isCheckChartPackStatus: "PatientChartPackForms/GetByAppointment",
    formsByChartPackId: "PatientChartPackForms/GetAllByAppointment",
    saveStatus: "PatientChartPackForms/SaveStatus",
    saveChartPackForms: "PatientChartPackForms/SaveForm",
    getformsData: "PatientChartPackForms/GetById",

    uploads: "PatientChartPackForms/SaveImage",
    attachment: "ChartPackForms/SaveUploads",
  },
  chartpack: {
    getAll: "ChartPack/GetAll",
    // getbyId: "ChartPack/GetById",
    getbyId: "ChartPackForms/GetAllByChartPack",
    getAllByfacility: "ChartPack/GetAllByFacility",
    add: "ChartPack/SaveChartPacks",

  },
  chartpacksection: {
    getAll: "ChartPackSection/GetAll",
    add: "ChartPackSection/Save",
    getbyId: "ChartPackSection/GetById",
    update: "ChartPackSection/UpdateOrderNumber",
  },
  config: {
    getAllByfacility: "Config/GetAllByFacilityId",
    getbyId: "Config/GetById",
    add: "Config/Save"
  },
  forms: {
    getAll: "Forms/GetAll",
    add: "Forms/Save",
    getbyId: "Forms/GetById",
    getAllByfacility: "Forms/GetAllByFacility",
    getAcyiveByFacility: "Forms/GetActiveByFacility",
  },
  addons: {
    races: "Races",
    nationality: "Nationality",
    language: "Language",
    cancelReasons: "CancellationReason",
    requestDemo: "RequestDemo",
  },
  authenticate: {
    login: "Login/Login",
    createPassword: "Login/CreatePassword",
    changePassword: "User/ChangePassword",
    getAccessLinks:"Login/GetUserAccessLinks"
  },
  appointment: {
    getAll: "Dashboard/Appointments",
    getAllehr: "Dashboard/ehr",
    getbyId: "Appointment/GetById",
    add: "Appointment/Save",
    statusChange: "Appointment/UpdateStatus",
    getActivatedList: "Appointment/GetActivatedList",
    save: "Appointment/SaveAppointment",
    detailedAppointment: "Appointment/GetAppointment",
    cancelAppointment: "Appointment/CancelAppointment",
    saveuploads: "Appointment/SaveUploads",
    getAppointmentsCount: "Dashboard/GetAppointmentStatusCount",
    checkPhysicianCredentials: "Appointment/CheckPhysicianCredentials",
    getAllFormData:"Appointment/GetAllFormsDataByAppointmentId"
  },
  appointmentChecklist: {
    getAll: "AppointmentCheckList/GetAppointmentCheckList",
    statusChange: "AppointmentCheckList/UpdateItemStatus",
    save: "AppointmentCheckList/Save",
  },
  calenderScheduleBlock: {
    getAll: "CalenderScheduleBlock/GetAll",
  },
  checkList: {
    getAll: "Checklist/GetAll",
    getbyId: "Checklist/GetById",
    add: "Checklist/Save",
    statusChange: "Checklist/UpdateStatus",
    getActivatedList: "Checklist/GetActivatedList",
    getAllByfacility: "Checklist/GetAllByFacility",
    getAcyiveByFacility: "Checklist/GetActiveByFacility",
    getAppointmentChecklist: "Checklist/GetAppointmentCheckList",
  },
  checkListCategory: {
    getAll: "ChecklistCategory/GetAll",
    getbyId: "ChecklistCategory/GetById",
    add: "ChecklistCategory/Save",
    statusChange: "ChecklistCategory/UpdateStatus",
    getActivatedList: "CheckListCategory/GetActivatedList",
    getAllByfacility: "CheckListCategory/GetAllByFacility",
    getAcyiveByFacility: "CheckListCategory/GetActiveByFacility",
  },
  CPTCode: {
    getAll: "CPTCode/GetAll",
    getbyId: "CPTCode/GetById",
    add: "CPTCode/Save",
    statusChange: "CPTCode/UpdateStatus",
    getActivatedList: "CPTCode/GetActivatedList",
    getAllByfacility: "CPTCode/GetAllByFacility",
    getAcyiveByFacility: "CPTCode/GetActiveByFacility",
  },
  equipment: {
    getAll: "Equipment/GetAll",
    getbyId: "Equipment/GetById",
    add: "Equipment/Save",
    statusChange: "Equipment/UpdateStatus",
    getActivatedList: "Equipment/GetActivatedList",
    getAllByfacility: "Equipment/GetAllByFacility",
    getAcyiveByFacility: "Equipment/GetActiveByFacility",
  },
  facility: {
    getAll: "Facility/GetAll",
    getbyId: "Facility/GetById",
    add: "Facility/Save",
    statusChange: "Facility/UpdateStatus",
    getActivatedList: "Facility/GetActivatedList",
    getList: "Facility/GetList",
    initiate: "Facility/InitiateFacility",
  },
  facilityGroup: {
    getAll: "FacilityGroup/GetAll",
    getbyId: "FacilityGroup/GetById",
    add: "FacilityGroup/Save",
    statusChange: "FacilityGroup/UpdateStatus",
    getActivatedList: "FacilityGroup/GetActivatedList",
  },
  fileCategory: {
    getAll: "FileCategory/GetAll",
    getbyId: "FileCategory/GetById",
    add: "FileCategory/Save",
    statusChange: "FileCategory/UpdateStatus",
    getActivatedList: "FileCategory/GetActivatedList",
    getAllByfacility: "FileCategory/GetAllByFacility",
    getAcyiveByFacility: "FileCategory/GetActiveByFacility",
  },
  formBuilder: {
    getAll: "FormBuilder/GetAll",
    getbyId: "FormBuilder/GetById",
    add: "FormBuilder/Save",
    statusChange: "FormBuilder/UpdateStatus",
    getActivatedList: "FormBuilder/GetActivatedList",
    getAllByfacility: "FormBuilder/GetAllByFacility",
    getAcyiveByFacility: "FormBuilder/GetActiveByFacility",
  },
  guarantor: {
    getAll: "Guarantor/GetAll",
    getbyId: "Guarantor/GetById",
    add: "Guarantor/Save",
    statusChange: "Guarantor/UpdateStatus",
    getActivatedList: "Guarantor/GetActivatedList",
    delete: "Guarantor/Delete",
  },
  inbox: {
    getAll: "Inbox/GetAll",
    getbyId: "Inbox/GetById",
    add: "Inbox/Save",
    patientInbox: "Inbox/GetPatientInbox",
  },
  insurance: {
    getAll: "Insurance/GetAll",
    getbyId: "Insurance/GetById",
    add: "Insurance/Save",
    statusChange: "Insurance/UpdateStatus",
    getActivatedList: "Insurance/GetActivatedList",
    delete: "Insurance/Delete",
  },
  ICD10Code: {
    getAll: "ICD10Code/GetAll",
    getbyId: "ICD10Code/GetById",
    add: "ICD10Code/Save",
    statusChange: "ICD10Code/UpdateStatus",
    getActivatedList: "ICD10Code/GetActivatedList",
    getAllByfacility: "ICD10Code/GetAllByFacility",
    getAcyiveByFacility: "ICD10Code/GetActiveByFacility",
  },
  manufacturer: {
    getAll: "Manufacturer/GetAll",
    getbyId: "Manufacturer/GetById",
    add: "Manufacturer/Save",
    statusChange: "Manufacturer/UpdateStatus",
    getActivatedList: "Manufacturer/GetActivatedList",
    getAllByfacility: "Manufacturer/GetAllByFacility",
    getAcyiveByFacility: "Manufacturer/GetActiveByFacility",
  },
  offices: {
    getAll: "Offices/GetAll",
    getbyId: "Offices/GetById",
    add: "Offices/Save",
    statusChange: "Offices/UpdateStatus",
    getActivatedList: "Offices/GetActivatedList",
    getList: "Offices/GetList",
  },
  patient: {
    getAll: "Patient/GetAll",
    getbyId: "Patient/GetById",
    add: "Patient/QuickSave",
    quickadd: "Patient/Save",
    save: "Patient/InsertPatient",
    statusChange: "Patient/UpdateStatus",
    getActivatedList: "Patient/GetActivatedList",
    getAllByfacility: "Patient/GetAllByFacility",
    getAcyiveByFacility: "Patient/GetActiveByFacility",
    getAppointmentHistory: "Patient/PatientHistory",
    getUploads: "Patient/GetAllUploads",
    SaveQuesquestionnaire: "Patient/SaveQuestionnaire",
    GetQuesquestionnaire: "Patient/GetQuestionnaire",
    GetLastQuesquestionnaire: "Patient/GetLastQuestionnaire",
    search: "Patient/Search"
  },
  diagnosis: {
    getAll: "Diagnosis/GetByFacilityId",
    save: "Diagnosis/Save",
  },
  physician: {
    getAll: "Physician/GetAll",
    getbyId: "Physician/GetById",
    add: "Physician/Save",
    statusChange: "Physician/UpdateStatus",
    getActivatedList: "Physician/GetActivatedList",
    setSchedule: "Physician/SetSchedule",
    getSpecialitybyPhysician: "Physician/GetSpecialityById",
    getActiveSpecialitybyPhysician: "Physician/GetActiveSpecialityById",
    getAllByfacility: "Physician/GetAllByFacility",
    getAcyiveByFacility: "Physician/GetActiveByFacility",
  },
  prefCard: {
    getAll: "PrefCardLink/GetList",
    getbyId: "PrefCardLink/GetById",
    add: "PrefCardLink/Save",
    delete: "PrefCardLink/Delete",
    statusChange: "PrefCardLink/UpdateStatus",
    getActivatedList: "PrefCardLink/GetActivatedList",
    getActivatedByphysician: "PrefCardLink/GetActiveByPhysician",
  },
  procedure: {
    getAll: "Procedure/GetAll",
    getbyId: "Procedure/GetById",
    add: "Procedure/Save",
    statusChange: "Procedure/UpdateStatus",
    getActivatedList: "Procedure/GetActivatedList",
    getAllByfacility: "Procedure/GetAllByFacility",
    getAcyiveByFacility: "Procedure/GetActiveByFacility",
  },
  room: {
    getAll: "Room/GetAll",
    getbyId: "Room/GetById",
    add: "Room/Save",
    statusChange: "Room/UpdateStatus",
    getActivatedList: "Room/GetActivatedList",
    getAllByfacility: "Room/GetAllbyFacility",
    getAcyiveByFacility: "Room/GetActiveByFacility",
  },
  role:{
    getAll:"Role/GetAll",
    getbyId:"ROle/GetById",
    add:"Role/Save",
  },
  reports: {
    getAll: "Reports/GetAll",
    exportPdf: "Reports/ExportToPDF",
    exportXls: "Reports/GetAllExcel",
    auditLog: "Reports/GetAuditLog"
  },
  schedule: {
    getAll: "Schedule/GetAll",
    getbyId: "Schedule/GetById",
    add: "Schedule/Save",
    statusChange: "Schedule/UpdateStatus",
    getActivatedList: "Schedule/GetActivatedList",
    delete: "Schedule/Delete",
    setAvailability: "Schedule/PhysicianScheduleAvailability"
  },
  scheduleBlock: {
    getAll: "ScheduleBlock/GetAll",
    getbyId: "ScheduleBlock/GetById",
    add: "ScheduleBlock/Save",
    statusChange: "ScheduleBlock/UpdateStatus",
    getActivatedList: "ScheduleBlock/GetActivatedList",
    delete: "ScheduleBlock/Delete",
  },
  smartNotification: {
    getAll: "SmartNotification/GetAll",
    getbyId: "SmartNotification/GetById",
    add: "SmartNotification/Save",
    statusChange: "SmartNotification/UpdateStatus",
    getAllByfacility: "SmartNotification/GetAllByFacility",
    getActivatedList: "SmartNotification/GetActivatedList",
    delete: "SmartNotification/Delete",
  },
  speciality: {
    getAll: "Speciality/GetAll",
    getbyId: "Speciality/GetById",
    add: "Speciality/Save",
    getSpecilityByFacilityId: "Speciality/GetSpecialityByFacility",
    statusChange: "Speciality/UpdateStatus",
    //getActivatedList: "Speciality/GetActivatedList",
    getPhysicianBySpeciality: "Speciality/GetPhysicianBySpeciality",
    getSpecialityByFacilities: "Speciality/GetByFacilities",
    getAllByfacility: "Speciality/GetAllByFacility",
    getAcyiveByFacility: "Speciality/GetActiveByFacility",
  },
  supplier: {
    getAll: "Supplier/GetAll",
    getbyId: "Supplier/GetById",
    add: "Supplier/Save",
    statusChange: "Supplier/UpdateStatus",
    getActivatedList: "Supplier/GetActivatedList",
  },
  supplies: {
    getAll: "Supplies/GetAll",
    getbyId: "Supplies/GetById",
    add: "Supplies/Save",
    statusChange: "Supplies/UpdateStatus",
    getActivatedList: "Supplies/GetActivatedList",
    getAllByfacility: "Supplies/GetAllByFacility",
    getAcyiveByFacility: "Supplies/GetActiveByFacility",
  },
  supplyCategory: {
    getAll: "SupplyCategory/GetAll",
    getbyId: "SupplyCategory/GetById",
    add: "SupplyCategory/Save",
    statusChange: "SupplyCategory/UpdateStatus",
    getActivatedList: "SupplyCategory/GetActivatedList",
    getAllByfacility: "SupplyCategory/GetAllByFacility",
    getAcyiveByFacility: "SupplyCategory/GetActiveByFacility",
  },
  user: {
    getAll: "User/GetAll",
    getbyId: "User/GetById",
    getBytoken: "User/GetUserInfoById",
    add: "User/Save",
    statusChange: "User/UpdateStatus",
    getActivatedList: "User/GetActivatedList",
    getAllByfacility: "User/GetAllByFacility",
    getAcyiveByFacility: "User/GetActiveByFacility",
    sendMail: "User/SendMailToSetPassword",
    setPassword: "User/SetPassword",
    forgotPassword: "User/SendForgotPasswordMail",
    access: "User/GetUserAccessLinks",
    getRoles: "User/GetRoles",
    initiate:"User/InitiateUser",
  },
  userAccess: {
    add: "UserAccess/UpdateAccessLinkStatus",
    getbyId: "UserAccess/GetRecursiveAccessLinks",
    getSubscriptions:"UserAccess/GetSubscriptions",
    statusChange: "UserAccess/UpdateUserAccessLinks"
  },
  cred: {
    termsAndConditions: "Config/GetTermsConditions",
    physicianAndNonphysician: "OnBoardingForms/GetOnBoardingForms",
    saveForm: "OnBoardingForms/Save",
    getAll: "OnBoardingForms/GetAllByUserId",
    getbyId: "OnBoardingForms/GetById",
    status: "OnBoardingForms/UpdateFormStatus",
    termsStatus: "User/UpdateTermsCondStatus",
    credApprove: "User/UpdateCredStatus",
    dashboard: "Dashboard/credentialing",
    dashboardById: "Dashboard/CredGetByUserId",
    saveUploads: "OnBoardingForms/SaveUploads",
    updateDate: "OnBoardingForms/UpdateCredCertificateInfo"
  },  
  item: {
    getAll: "Item/GetAll",
    getbyId: "Item/GetById",
    add: "Item/Save",
    statusChange: "Item/UpdateStatus",
    getActivatedList: "Item/GetActivatedList",
    getAllByfacility: "Item/GetAllbyFacility",
    getAcyiveByFacility: "Item/GetActiveByFacility",
  },
  reviews:{
    // Admin
    addSurvey:"Review/InsertReview",
    getAll:"Review/GetAll",
    getAllReviews:"Review/GetAllReviews",
    getById:"Review/GetById",
    saveparticipants:"Review/InsertReviewFor",
    participantGetById:"Review/GetByIdParticipants",
    getAllParticipants:"Review/GetAllParticipants",
    getSubmittedReviews:"Review/GetSubmittedReviews",
    // physician
    getAllPhysicianReviews:"Review/GetAllPhysicianReviews",
    getReviewById:"Review/GetByIdPhysicianreview",
    saveReview:"Review/InsertResponse",
  },

};
